import { gql } from "@apollo/client";
export const HASHNODE_API_URL = "https://gql.hashnode.com/";

export const GET_BLOGS = gql`
  query Publication($host: String!) {
    publication(host: $host) {
      id
      isTeam
      title
      posts(first: 30) {
        edges {
          node {
            id
            publishedAt
            author {
              username
              name
            }
            title
            brief
            url
            content {
              text
              markdown
            }
            coverImage {
              url
            }
            readTimeInMinutes
          }
        }
      }
    }
  }
`;
