import React, { useEffect, useState, useRef } from "react";
import "./SideDrawer.css";
import { IoCloseSharp } from "react-icons/io5";
import Logo from "../Logo/Logo";
import "../ToggleMenu/ToggleMenu.css";
// import Button from '../Button/Button'
import LinkButton from "../LinkButton/LinkButton";
import { Trans } from "@lingui/macro";
import LangButton from "../LangButton/LangButton";
import { Link } from "react-router-dom";

function SideDrawer({ show, onClose, onClickContactUs }) {
  const [attachedClasses, setAttachedClasses] = useState("close");
  // const [isButtonVisible, setIsButtonVisible] = useState(false);
  const sidebarRef = useRef(null);
  const firstFocusableElementRef = useRef(null);
  const lastFocusableElementRef = useRef(null);

  // const updateButtonVisibility = () => {
  //   const screenWidth = window.innerWidth;
  //   setIsButtonVisible(screenWidth < 770);
  // };

  const handleTabKey = (e) => {
    if (e.key === "Tab") {
      if (
        e.shiftKey &&
        document.activeElement === firstFocusableElementRef.current
      ) {
        lastFocusableElementRef.current.focus();
        e.preventDefault();
      } else if (
        !e.shiftKey &&
        document.activeElement === lastFocusableElementRef.current
      ) {
        firstFocusableElementRef.current.focus();
        e.preventDefault();
      }
    }

    if (e.keyCode === 27) {
      onClose();
    }
  };

  useEffect(() => {
    if (show) {
      setAttachedClasses("open");
      //   const closeButton = document.querySelector('.toggle-menu-btn-close')
      //   closeButton && closeButton.focus()

      const focusableElements = sidebarRef.current.querySelectorAll(
        'a[href], button:not([disabled]), textarea, input, select, [tabindex]:not([tabindex="-1"])'
      );

      firstFocusableElementRef.current = focusableElements[0];
      lastFocusableElementRef.current =
        focusableElements[focusableElements.length - 1];
      //   setTimeout(() => {
      firstFocusableElementRef.current.focus();
      //   }, 100);

      document.addEventListener("keydown", handleTabKey);
      // window.addEventListener('resize', updateButtonVisibility)
      // updateButtonVisibility()
    }

    return () => {
      document.removeEventListener("keydown", handleTabKey);
      // window.removeEventListener('resize', updateButtonVisibility)
    };
  }, [show]);

  return (
    show && (
      <div
        role="dialog"
        aria-modal="true"
        className={`side-drawer ${attachedClasses}`}
        aria-hidden={!show}
        ref={sidebarRef}
        aria-labelledby="sidebar"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "21px",
            flexDirection: "row-reverse",
          }}
        >
          <button
            className="toggle-menu-btn toggle-menu-btn-close"
            aria-label="Close Menu"
            onClick={onClose}
          >
            <IoCloseSharp color="black" size="25" aria-hidden="true" />
          </button>
          <Logo
            style={{ marginLeft: "15px", height: "28px" }}
            altText="A11ySolutions"
          />
        </div>
        <span id="sidebar" className="sr-only" aria-hidden="true">
          <Trans>Menu</Trans>
        </span>
        <nav aria-label="Main Menu">
          <ul className="side-drawer-links">
            <li>
              <Link onClick={onClose} to="/#why-choose-us">
                <Trans>Why choose us</Trans>
              </Link>
            </li>
            <li>
              <Link onClick={onClose} to="/#our-services">
                <Trans>Services</Trans>
              </Link>
            </li>
            <li>
              <Link onClick={onClose} to="/#our-products">
                <Trans>Products</Trans>
              </Link>
            </li>
            <li>
              <Link onClick={onClose} to="/#resources">
                <Trans>Resources</Trans>
              </Link>
            </li>
            <li>
              <Link onClick={onClose} to="/#clients">
                <Trans>Clients</Trans>
              </Link>
            </li>
            <li>
              <Link onClick={onClose} to="/#why-choose-us">
                <Trans>Services Politics</Trans>
              </Link>
            </li>
            <li>
              <Link onClick={onClose} to="/#why-choose-us">
                <Trans>Terms and Conditions</Trans>
              </Link>
            </li>
            <li className="side-drawer-button">
              {/* { isButtonVisible && */}
              <LinkButton className="link" onClick={onClickContactUs}>
                <Trans>Contact us</Trans>
              </LinkButton>
              {/* } */}
            </li>
            <li>
              <LangButton />
            </li>
          </ul>
        </nav>
        {/* <div className="side-drawer-button">
        </div> */}
      </div>
    )
  );
}

export default SideDrawer;
