import { Trans, t } from "@lingui/macro";
import BicolorTitledText from "../BicolorTitledText/BicolorTitledText";
import { ImageDivider } from "../ImageDivider/ImageDivider";
import ServiceCard from "../ServiceCard/ServiceCard";
import "./Resources.css";

export default function Resources() {
  return (
    <div className="resources-section" id="resources">
      <div className="resources-text">
        <BicolorTitledText
          color1="white"
          color1Text={t`Resources`}
          className={"home-resources"}
        >
          <p>
            {t`Dive into our blog articles, stay updated with our webinars,`}
            <br />
            {t`leverage our comprehensive documentation to ensure your`}
            <br />
            {t`and not only maintain but also attract new users.`}
          </p>
        </BicolorTitledText>
      </div>

      <div className="resources-container">
        <ServiceCard header={"Blog"} href={"/blog"} imgSrc={"/blog-icon.png"}>
          <p>
            <Trans>
              Discover the latest insights, tips, and trends <br />
              in accessibility to help you
              <br />
              create inclusive and impactful <br />
              digital experiences.{" "}
            </Trans>
          </p>
        </ServiceCard>
        <ServiceCard
          header={"Webinar"}
          buttonDisabled={true}
          buttonText="Coming soon"
          imgSrc={"/webinar-icon.png"}
        >
          <Trans>
            <p>
              Learn from our experts through <br />
              webinars, gaining practical strategies
              <br />
              to improve accessibility
              <br />
              and enhance your digital products.
            </p>
          </Trans>
        </ServiceCard>
        <ServiceCard
          header={t`Documentation`}
          buttonDisabled={true}
          buttonText="Coming soon"
          imgSrc={"/documentation-icon.png"}
        >
          <p>
            <Trans>
              Access essential templates and guides
              <br />
              to streamline accessibility, <br />
              including VPATs, Accessibility
              <br />
              Statements, and more.
            </Trans>
          </p>
        </ServiceCard>
      </div>
      <ImageDivider bottom />
    </div>
  );
}
