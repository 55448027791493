import ENGLISH_ICON from "../assets/flags/usa.svg";
import SPANISH_ICON from "../assets/flags/spain.svg";

export const LANGS = {
  en: {
    icon: ENGLISH_ICON,
    text: "ENGLISH",
  },

  es: {
    icon: SPANISH_ICON,
    text: "ESPAÑOL",
  },
};

export const LANG_LIST = ["en", "es"];

export const HASHNODE_HOST_ENGLISH = "a11ysolutions.hashnode.dev";
export const HASHNODE_HOST_SPANISH = "a11ysolutions-es.hashnode.dev";
