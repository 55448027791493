import React from "react";
import "./ContactInfo.css";
import { Trans } from "@lingui/macro";

//import Logo from "../Logo/Logo"

function ContactInfo({ reference }) {
  return (
    <div style={{ marginTop: "4em" }} id="contact-us">
      <h2
        tabIndex="-1"
        ref={reference}
        style={{ textTransform: "uppercase", fontSize: "1em" }}
      >
        <Trans>Contact us</Trans>
      </h2>
      <div className="contact-info-item">
        <Trans>+1 (407) 535-1932</Trans>
      </div>
      <div className="contact-info-item">
        <Trans>a11ycontact@a11ysolutions.com</Trans>
      </div>
      <div className="contact-info-item">
        <Trans>Miami, Florida, US.</Trans>
      </div>
      <a
        href="https://www.accessibilityassociation.org/s/"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Visit the International Association of Accessibility Professionals website"
      >
        <img
          src="./organizational-member.png"
          width="200px"
          alt="Organizational member of International Association of Accessibility Professionals"
        ></img>
      </a>
    </div>
  );
}
export default ContactInfo;
